@import './color';
@import './variables';

html {
	box-sizing: border-box;
	font-size: 16px;
	font-family: Montserrat-Regular, sans-serif;
	height: 100%;
}
body {
	height: 100%;
}
* {
	margin: 0;
	padding: 0;
}
*,
*:before,
*:after {
	box-sizing: border-box;
}
input,
textarea,
select,
button {
	outline: none;
}
input {
	line-height: normal;
}
label,
button {
	cursor: pointer;
}
a {
	text-decoration: none;
}
a.active {
	background-color: rgb(0, 110, 255) !important;
}
img {
	display: block;
	max-width: 100%;
}

table {
	table-layout: fixed;
	word-wrap: break-word;
}

.td-style {
	position: relative;
	width: 100%;
	display: -webkit-box;
	line-height: 3rem;
	-webkit-line-clamp: 1;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
	word-break: break-word;

	span {
		display: none;
		width: 3rem;
		height: 3rem;
		justify-content: center;
		align-items: center;
		position: absolute;
		background-color: #fff;
		white-space: nowrap;
		padding: 0.5rem;
		border-radius: 4rem;
		box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
		cursor: pointer;
		bottom: 0;
		right: 0;
	}

	&:hover {
		span {
			display: flex;
		}
	}
}

.overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.507);
}
