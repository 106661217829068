.textArea {
	height: 15rem;
	resize: none;
}

.groupBtn {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 2rem;
}

.submit {
	min-width: 100px;
}
