.buttonGroup {
	display: flex;
	justify-content: center;
	align-items: stretch;
	font-size: 0.5rem;

	button{
		width: 150px;
	}

	button + button {
		margin-left: 20px;
	}
}

.textArea {
	height: 180px;
	resize: none;
}

.form {
	border-bottom: solid 1px #ccc;
}

.listNotify {
	border: 1px solid #ccc;
	height: 200px;
	border-radius: 0.4rem;
	padding: 1rem;
	overflow-y: scroll;
	margin-bottom: 30px;

	.itemNotify {
		color: rgb(0, 119, 255);
	}
}

.marginBottom20 {
	margin-bottom: 20px;
}
