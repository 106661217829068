.main {
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(247, 247, 247);
}

.title {
	text-align: center;
}

.form {
	max-width: 40rem;
	min-width: 30rem;
	margin: auto;
	box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
	padding: 2rem;
	border-radius: 1rem;
	background-color: #fff;
}
